<template>
  <div class="container">
    <b-form @submit.prevent="onSubmit">
      <h3>Регистрация врача</h3>

      <b-form-group id="email" label-for="email" label="Email">
        <b-form-input id="email" type="text" placeholder="" required v-model="doctor.email" autocomplete="off"></b-form-input>
      </b-form-group>
      <b-form-group id="password" label-for="password" label="Пароль" invalid-feedback="Пароль должен быть от 8 до 32 символов">
        <b-form-input id="password" type="password" required placeholder="Введите пароль" v-model="doctor.password" :state="doctor.password && doctor.password.length > 7" autocomplete="off"></b-form-input>
      </b-form-group>
      <b-form-group id="passwordRepeat" label-for="passwordRepeat" label="Повторите пароль" invalid-feedback="Пароли не совпадают">
        <b-form-input id="passwordRepeat" type="password" required placeholder="Введите пароль" v-model="doctor.passwordRepeat"  :state="doctor.passwordRepeat && doctor.password === doctor.passwordRepeat" autocomplete="off"></b-form-input>
      </b-form-group>
      <b-form-group id="firstname" label-for="firstname" label="Имя">
        <b-form-input id="firstname" type="text" placeholder="Например, Иван" required v-model="doctor.firstname" autocomplete="off"></b-form-input>
      </b-form-group>
      <b-form-group id="middlename" label-for="middlename" label="Отчество">
        <b-form-input id="middlename" type="text" placeholder="Например, Иванович" v-model="doctor.middlename" autocomplete="off"></b-form-input>
      </b-form-group>
      <b-form-group id="lastname" label-for="lastname" label="Фамилия">
        <b-form-input id="lastname" type="text" placeholder="Например, Иванов" required v-model="doctor.lastname" autocomplete="off"></b-form-input>
      </b-form-group>
      <b-form-group id="phoneNumber" label-for="phoneNumber" label="Номер телефона">
        <b-form-input id="phoneNumber" v-mask="'+ # (###) ###-##-##'"  placeholder="+ 7 (999) 999-99-99"  type="text" v-model="doctor.phoneNumber" autocomplete="off"></b-form-input>
      </b-form-group>
<!--      <b-form-group id="genderCode" label-for="genderCode" label="Пол">-->
<!--        <b-form-select id="genderCode" v-model="doctor.genderCode" :options="genderOptions" :select-size="genderOptions.length"></b-form-select>-->
<!--      </b-form-group>-->
      <b-form-group id="regionCode" label-for="regionCode" label="Регион">
        <b-form-select id="regionCode" v-model="doctor.regionCode" :options="regionOptions | mainRussiaRegions" :select-size="1"></b-form-select>
      </b-form-group>
      <b-form-group id="doctorComment" label-for="doctorComment" label="Комментарий">
        <b-form-textarea
            id="doctorComment"
            v-model="doctor.doctorComment"
            rows="2"
            max-rows="2"
        ></b-form-textarea>
        <!--        <ckeditor class="ck-content" :editor="editor" v-model="doctor.doctorComment"-->
        <!--                  :config="editorConfig"></ckeditor>-->
      </b-form-group>

      <b-form-group id="clinics" label-for="clinics" label="Клиники">

        <treeselect name="clinics" id="clinics" placeholder=""
                    clearable searchable
                    open-on-click disableFuzzyMatching
                    :noResultsText="'Не найдено...'"
                    :options="clinicOptions"
                    :max-height="200"
                    :normalizer="normalizeTreeselect"
                    v-model="doctor.clinicId" />
<!--        <div v-if="">-->
<!--          <b-form-input type="" v-model/>-->
<!--        </div>-->
      </b-form-group>

      <b-form-group id="specializations" label-for="specializations" label="Специализации">
        <treeselect name="specializations" id="specializations" placeholder=""
                    clearable searchable multiple
                    open-on-click disableFuzzyMatching
                    :noResultsText="'Не найдено...'"
                    :options="specializationOptions"
                    :max-height="200"
                    :normalizer="normalizeTreeselect"
                    v-model="doctor.specs" />
      </b-form-group>

      <b-form-checkbox v-model="pinAccept">Предоставлен PIN-код</b-form-checkbox>

      <b-form-group v-if="pinAccept" id="pin" label-for="pin" label="PIN-код">
        <b-form-input id="pin" type="text" placeholder="" v-model="doctor.pin" autocomplete="off"></b-form-input>
      </b-form-group>


      <b-form-checkbox v-model="approve" required :state="approve">Я согласен на обработку моих персональных данных</b-form-checkbox>


      <b-button :disabled="!approve" variant="info" type="submit" >Зарегистрироваться<b-spinner v-if="saving" variant="light" small></b-spinner></b-button>

      <div>
        Защита от спама reCAPTCHA
        <a href="https://policies.google.com/privacy">Конфиденциальность</a> и
        <a href="https://policies.google.com/terms">Условия использования</a>.
      </div>
      &nbsp;

    </b-form>

    <br />
    <br />

    <b-modal id="clinicModal" hide-footer size="lg" title="Успешная регистрация" @close="closeModal()" no-close-on-backdrop no-close-on-esc >
      <div>
        После регистрации, перед активацией доступа, производится проверка на наличие у вас высшего медицинского образования и возможности предоставления вам доступа в личный кабинет. Мы с вами свяжемся и оповестим вас об активации доступа.
      </div>

    </b-modal>

  </div>
</template>

<script>
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {host, roles} from "@/constants";
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "DoctorRegister",
  computed: {
    isAuthorized: {
      get() {
        return this.$store.getters.isAuthorized
      },
      set(value) {
        this.$store.commit('updateIsAuthorized', value)
      }
    },
    role: {
      get() {
        return this.$store.getters.role
      },
      set(value) {
        this.$store.commit('updateRole', value)
      }
    },
    rolename: {
      get() {
        return this.$store.getters.rolename
      },
      set(value) {
        this.$store.commit('updateRolename', value)
      }
    },
    username: {
      get() {
        return this.$store.getters.username
      },
      set(value) {
        this.$store.commit('updateUsername', value)
      }
    },
    userId: {
      get() {
        return this.$store.getters.userId
      },
      set(value) {
        this.$store.commit('updateUserId', value)
      }
    },
    activationDaysLeftCount: {
      get() {
        return this.$store.getters.activationDaysLeftCount
      },
      set(value) {
        this.$store.commit('updateActivationDaysLeftCount', value)
      }
    },
    reportDetailedBlock: {
      get() {
        return this.$store.getters.reportDetailedBlock
      },
      set(value) {
        this.$store.commit('updateReportDetailedBlock', value)
      }
    },
    reportGeneralBlock: {
      get() {
        return this.$store.getters.reportGeneralBlock
      },
      set(value) {
        this.$store.commit('updateReportGeneralBlock', value)
      }
    },
    reportConciseBlock: {
      get() {
        return this.$store.getters.reportConciseBlock
      },
      set(value) {
        this.$store.commit('updateReportConciseBlock', value)
      }
    },
    reportPatientBlock: {
      get() {
        return this.$store.getters.reportPatientBlock
      },
      set(value) {
        this.$store.commit('updateReportPatientBlock', value)
      }
    },
    reportGraphicBlock: {
      get() {
        return this.$store.getters.reportGraphicBlock
      },
      set(value) {
        this.$store.commit('updateReportGraphicBlock', value)
      }
    },
    permission: {
      get() {
        return this.$store.getters.permission
      },
      set(value) {
        this.$store.commit('updatePermission', value)
      }
    }
  },
  components: { Treeselect },
  data() {
    return {
      pinAccept: false,
      saving:false,
      regionOptions: [],
      clinicOptions: [],
      specializationOptions: [],
      clinicId: null,
      specId: null,
      doctor:  {
        firstname: '',
        middlename: '',
        lastname: '',
        genderCode: 1,
        regionCode: 6611,
        password: null,
        passwordRepeat: null,
        phoneNumber: '',
        email: '',
        doctorComment: '',
        token: null,
        specs: [],
        clinicId: null,
        pin: null
      },
      approve: false,
//      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      },
      normalizeTreeselect(node) {
        //console.log('normalize ' + JSON.stringify(node))
        return {
          id: node.value,
          label: node.text
        }
      },
      genderOptions: [
        {
          value: 1,
          text: "Мужской"
        },
        {
          value: 2,
          text: "Женский"
        }
      ],
    }
  },
  filters: {
    mainRussiaRegions: function (options) {
      let result = []
      for (let o of options) {
        if (o.parentRegionId === 1286 && o.isMain === true) {
          result.push(o)
        }
      }

      return result
    }
  },
  methods: {

    closeModal() {
      this.$router.push('/')
    },
    async onSubmit() {
      if (!this.approve) {
        this.$errorToast('Вам необходимо дать согласие на обработку персональных данных')
        return
      }


      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      this.doctor.token = await this.$recaptcha('login')

      console.log('token = ' + this.doctor.token)

      this.saving = true
      let method = 'POST'

      if (!this.pinAccept) {
        this.doctor.pin = null
      }

      let rs = await fetch(host + '/rest/doctors/self', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.doctor),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await rs.json()

      if (json.resultCode != 0) {
        this.$errorToast(json.resultCode + ' ' + json.message)
        this.saving = false
        return
      } else {
        if (json.canOpenSession) {
          try {
            rs = await fetch(host + '/rest/session', {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify({login: this.doctor.email, password: this.doctor.password}),
              headers: {'Content-Type': 'application/json; charset=utf-8'}
            })
            json = await rs.json()

            if (json.resultCode == 0) {
              this.isAuthorized = true
              this.role = json.role
              this.rolename = roles.filter(t => t.id == this.role)[0].name
              this.username = json.username
              this.userId = json.userId
              this.activationDaysLeftCount = json.activationDaysLeftCount
              this.reportDetailedBlock = json.reportDetailedBlock
              this.reportGeneralBlock = json.reportGeneralBlock
              this.reportConciseBlock = json.reportConciseBlock
              this.reportPatientBlock = json.reportPatientBlock
              this.reportGraphicBlock = json.reportGraphicBlock
              this.permission = json.permission

              if (this.role == 3) {
                this.$router.push('/analits')
              }  else if (this.role == 2) {
                this.$router.push('/patients')
              }  else {
                this.$router.push('/patients/' + this.userId)
              }
            } else {
              this.$errorToast(json.message);
            }
            this.saving = false
          } catch(error) {
            this.$errorToast(error);
            this.saving = false
          }
        } else {
          this.$bvModal.show('clinicModal')
        }

      }

      // let localDocId = this.doctorId
      // if (!this.doctorId) {
      //   localDocId = json.entityId
      // }
      // json = await this.updateDoctorClinics(localDocId)
      // if(json) {
      //   this.loading = false
      //   return
      // }
      // json = await this.updateDoctorSpecializations(localDocId)
      // if(json) {
      //   this.saving = false
      //   return
      // }
      this.saving = false
      //this.$router.push('/doctors')

    },
    fetchDictsData() {
      fetch(host + '/rest/dicts/region')
          .then(response => response.json())
          .then(json => {
            this.regionOptions = json.options
            this.loading = false
          })
      fetch( host + '/rest/dicts/clinic', {
        credentials: 'include'
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode === 0) {
              this.clinicOptions = json.options
              console.log('clinicOptions' + JSON.stringify(this.clinicOptions))
            }
            this.loading = false
          })
      fetch( host + '/rest/dicts/doctorspecialization', {
        credentials: 'include'
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode === 0) {
              this.specializationOptions = json.options
            }
            this.loading = false
          })

    },
  },
  mounted() {

    this.fetchDictsData()
  }
}
</script>

<style scoped>

</style>